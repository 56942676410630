import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../style/HomePage.css";
import welcomeImg from "../images/Welcome_4_optimized.webp";
import { TextField, Button, IconButton } from "@mui/material";
import CloseIcon from "../images/closeIcon.webp";

const HomePageComponent = () => {
  const navigate = useNavigate();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    mobile: false,
    message: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleEnquireButtonClick = () => {
    setIsFormVisible(true);
  };

  const handleCloseButtonClick = () => {
    setIsFormVisible(false);
    setIsSubmitted(false);
    setErrors({
      name: false,
      email: false,
      mobile: false,
      message: false,
    });
    setFormData({
      name: "",
      email: "",
      mobile: "",
      message: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile" && !(/^\d+$/.test(value))) {
      return; 
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitted(true);

    const requiredFields = ["name", "email", "mobile", "message"];
    const newErrors = {};
    let hasErrors = false;
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = true;
        hasErrors = true;
      } else {
        newErrors[field] = false;
      }
    });

    setErrors(newErrors);

    if (hasErrors) {
      return;
    }

    const jsonData = JSON.stringify(formData);
    console.log("Form Data in JSON format:", jsonData);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer sCZhEkrTkD5s");
    myHeaders.append("Content-Type", "application/json");

    const raw = jsonData;
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.shvx.in/api/v1/lead/create", requestOptions)
      .then((response) => {
        if (response.ok) {
          setFormData({
            name: "",
            email: "",
            mobile: "",
            message: "",
          });
          setIsSubmitted(false);
          navigate("/thank-you");
        } else {
          throw new Error("Failed to submit form");
        }
        return response.text();
      })
      .then((result) => console.log(result))
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="parent-container">
        <div className="city-view">
          <div className="image-title">
            <p>Experience the green side </p>
            <p>of city living.</p>
          </div>
        </div>
        <div className="color-background"></div>
        <img
        loading="lazy"
          src={welcomeImg}
          alt="Welcome to Skylark"
          className="welcomeImage"
        />
        <button className="enquireButton" onClick={handleEnquireButtonClick}>
          ENQUIRE NOW
        </button>
        <div
          className={`enquire-form-container ${isFormVisible ? "visible" : ""}`}
        >
          <div className="enquiry-form">
            <div className="enquiry-form-optin-close">
              <IconButton onClick={handleCloseButtonClick}>
                <img loading="lazy" src={CloseIcon} alt="CloseIcon" className="close-button" />
              </IconButton>
            </div>
            <div className="form-content">
              <form
                className="enquiry-form-optin"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <TextField
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  autoComplete="name"
                  error={isSubmitted && errors.name}
                  helperText={
                    isSubmitted && errors.name ? "Name is required" : ""
                  }
                />
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={isSubmitted && errors.email}
                  helperText={
                    isSubmitted && errors.email ? "Email is required" : ""
                  }
                  autoComplete="email"
                />
                <TextField
                  label="Mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={isSubmitted && errors.mobile}
                  helperText={
                    isSubmitted && errors.mobile ? "Mobile is required" : ""
                  }
                  inputProps={{
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                    maxLength: 10,
                  }}
                />
                <TextField
                  label="Message"
                  name="message"
                  variant="outlined"
                  margin="normal"
                  value={formData.message}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={4}
                />
                <Button
                  variant="contained"
                  type="submit"
                  className="submit-btn"
                >
                  SUBMIT
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <p className="addressText">
        Skylark, CTS No. 811/812, Opp. Dolphin Aquarium, Irla Passage Road, Vile
        Parle (West), Mumbai - 400056. <b>(RERA No: P51800050748)</b>
      </p>
    </>
  );
};

export default HomePageComponent;
