import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "../style/EnquiryForm.css";
import { useNavigate } from "react-router-dom";

const EnquiryFormComponent = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    mobile: false,
    message: false,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitted(true);

    const requiredFields = ["name", "email", "mobile", "message"];
    const newErrors = {};
    let hasErrors = false;
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = true;
        hasErrors = true;
      } else {
        newErrors[field] = false;
      }
    });

    setErrors(newErrors);

    if (hasErrors) {
      return;
    }

    const jsonData = JSON.stringify(formData);
    console.log("Form Data in JSON format:", jsonData);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer sCZhEkrTkD5s");
    myHeaders.append("Content-Type", "application/json");

    const raw = jsonData;
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.shvx.in/api/v1/lead/create", requestOptions)
      .then((response) => {
        if (response.ok) {
          setFormData({
            name: "",
            email: "",
            mobile: "",
            message: "",
          });
          setIsSubmitted(false);
          navigate("/thank-you"); 
        } else {
          throw new Error("Failed to submit form");
        }
        return response.text();
      })
      .then((result) => console.log(result))
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  return (
    <div className="enquiry-form-1">
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          label="Name"
          name="name"
          fullWidth
          value={formData.name}
          onChange={handleChange}
          className="custom-textfield"
          required
          autoComplete="name"
          error={isSubmitted && errors.name}
          helperText={isSubmitted && errors.name ? "Name is required" : ""}
        />
        <TextField
          variant="outlined"
          label="Email"
          type="email"
          name="email"
          fullWidth
          value={formData.email}
          onChange={handleChange}
          className="custom-textfield"
          required
          autoComplete="email"
          error={isSubmitted && errors.email}
          helperText={isSubmitted && errors.email ? "Email is required" : ""}
        />
        <TextField
          variant="outlined"
          label="Mobile"
          type="tel"
          name="mobile"
          fullWidth
          value={formData.mobile}
          onChange={handleChange}
          className="custom-textfield"
          required
          error={isSubmitted && errors.mobile}
          helperText={
            isSubmitted && errors.mobile ? "Mobile is required" : ""
          }
        />
        <TextField
          variant="outlined"
          label="Message"
          multiline
          rows={4}
          name="message"
          fullWidth
          value={formData.message}
          onChange={handleChange}
          className="custom-textfield"
        />
        <Button variant="contained" type="submit" className="submit-button">
          SUBMIT
        </Button>
      </form>
    </div>
  );
};

export default EnquiryFormComponent;
