import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ThankYouPage = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/");
  };

  return (
    <div className="thank-you-container">
      <div className="thank-you-content">
        <p>Thanks for submitting!</p>
        <Button
          variant="contained"
          className="thank-you-ok-btn"
          styl
          onClick={handleButtonClick}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default ThankYouPage;
